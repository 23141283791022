@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
}

#root {
    height: 100%;
}

#root .ant-layout {
    height: 100%;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
    border-bottom: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.custom-layout-content {
    background-color: white;
    position: relative;
    padding: 24px;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.custom-placeholder-select .ant-select-selection-placeholder {
    color: black;
    opacity: 1;
}

/* Số lượng dòng cho phép  */
.number-of-line-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

/* custom scroll  */
/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eee;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.list_container {
    overflow: auto;
    height: 50px;
    width: 40px;
}

/* custom form  */
.custom-form-item .ant-form-item {
    margin-bottom: 0;
}

.custom-form-item {
    margin-bottom: 0;
}

.custom-submenu-scroll .ant-menu-sub {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}

@keyframes opacityChange {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.item-hover {
    opacity: 0;
}

.item-hover:hover {
    opacity: 1;
    cursor: pointer;
    animation: opacityChange 0.5s both;
}

.align-top {
    display: table-cell;
    vertical-align: top;
    height: 100%;

}

.custom-ckeditor-border .cke_editable {
    border: 1px solid red !important;
}